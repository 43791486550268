<template>
  <div class="message-container">
    <div class="message-title">
      <h2>Página no encontrada</h2>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';


export default {
    name: "PageNotFound"
};
</script>

<style lang="scss" scoped>

.message-title {
  margin-bottom: 10px !important;
  padding-bottom: 25px !important;
  padding-top: 10px !important;
}

.message-container {
  text-align: center;
  margin-top: 13%;
}

.grey-text {
  color: grey;
}

</style>
